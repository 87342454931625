import React, { useEffect, useState } from "react";
import { Link } from "@StarberryUtils"
import { Container, Row, Col } from 'react-bootstrap';
import $ from 'jquery'
import Starberry from "../../images/starberry.svg";
import "./Footer.scss";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown"
import loadable from "@loadable/component";
// import ContactForm from "@Components/forms/general-enquiry"

//import CookieConsent from "react-cookie-consent"
import CookieConsent from "../CookieConsent/CookieConsent";
import { CONTACTFORM_PAGE_URL } from "@Components/common/site/constants";
// import PopularSearch from "@Components/popular-search/index";
// import PopularSearchResults from "@Components/popular-search/popular-search-results";
// import PopularSearchDetail from "@Components/popular-search/popular-search-detail";
import { getSrc } from "gatsby-plugin-image"
import GetURL from "@Components/common/site/get-url";
const PopularSearch = loadable(() => import('@Components/popular-search/index'))
const PopularSearchResults = loadable(() => import('@Components/popular-search/popular-search-results'))
const PopularSearchDetail = loadable(() => import('@Components/popular-search/popular-search-detail'))
// import loadable from "@loadable/component";
const ContactForm = loadable(() => import('@Components/forms/general-enquiry'))
const { YomdelChatScript } = require("@starberry/react-chat-widgets/Yomdel/Chat")

const Footer = (props) => {
    const [showIframe, setshowIframe] = useState(false)
    const [renderComponent, setRenderComponent] = useState(false);
    var injectScript = true;
  
    React.useEffect(() => {
     
      window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
          InjectsSctipts()
        }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            InjectsSctipts()
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            InjectsSctipts()
            }
        })      
    }, [])

    useEffect(() => {
        $(".footer_scroll_top").click(function () {
            $("html, body").scrollTop(0);
        })
      
    }, [])

    function InjectsSctipts(){
        if(injectScript == true){
            //Chatbot inject script
            // const script = document.createElement('script');  
            // script.src = "https://clients.yomdel.com/tools/chat_script.js?url=" + document.location.href
            // script.defer = true;
            // script.async = true;      
            // document.body.appendChild(script);
            //Rightmove Inject script
            const script1 = document.createElement('script');  
            script1.src = "https://bymtrackinglive.azurewebsites.net/Script/Track/bentleyhurst.briefyourmarket.com/"
            script1.defer = true;
            script1.async = true;      
            document.body.appendChild(script1);
            injectScript=false
        }
    }
    const year = new Date().getFullYear();
    const data = useStaticQuery(graphql`
    query{
        glstrapi {
            footer(publicationState: LIVE) {
        Footer_Content
        Facebook_Link
        Instagram_Link
        Linkedin_Link
        Office_Details
        Show_Clients
        Twitter_Link
        Youtube_Link
        footer_links {
            label
            Menu_item {
              URL
            }
        }
        Clients {
            Client_Url
            Client_Logo{
                alternativeText
                url
                url_sharp {
                    childImageSharp {
                      gatsbyImageData(formats: AUTO
                        width: 180
                        quality: 80
                        layout: FIXED
                        transformOptions: {cropFocus: CENTER, fit: COVER}
                      )
                    }
                  }
            }
          }
      }
    }
}
    `);

    return (
        <footer className="footer">
            <CookieConsent/>
            <Container>
                {props.popularsearch === "searchresults" &&
                    <PopularSearchResults searchfileds={props.searchParams} />
                }
                {props.popularsearch === "propertydetails" &&
                    <PopularSearchDetail searchfileds={props.propertydetails} />
                }
                {props.popularsearch !== "searchresults" && props.popularsearch !== "propertydetails" &&
                    <PopularSearch {...props} />
                }
                <Row>
                    <Col lg="6">
                        <Row>
                            <Col lg="12">
                                <div className="footer-form-title">
                                    <div className="rich-text-editer-block">
                                        <ReactMarkdown source={data.glstrapi?.footer?.Footer_Content} allowDangerousHtml />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="footer-form">
                            { renderComponent &&
                            <ContactForm formname={true} />
                            }

                        </div>
                    </Col>
                    <Col lg="6" className="d-xl-flex">
                        <div className="office-details">

                            <h2>Office Details</h2>
                            <div className="rich-text-editer-block">
                                <ReactMarkdown source={data.glstrapi?.footer?.Office_Details} allowDangerousHtml />
                                <p><a href="tel:0161 543 0310">0161 543 0310</a><br />
                                    <Link to={CONTACTFORM_PAGE_URL.alias}>hello@bentleyhurst.co.uk</Link></p>
                            </div>
                            <div class="fixed-btns d-flex align-items-center justify-content-between d-lg-none property-details-mobile-btn allpage-mobile-btn"><a class="dark-bg btn btn-primary d-md-none" href="/property-valuation">Book a valuation</a></div>
                        </div>
                        <div className="social-media">
                            <h2>Connect with us</h2>
                            <div className="social-media-icons">
                                {data.glstrapi?.footer?.Facebook_Link ?
                                    <Link target="_blank" href={data.glstrapi?.footer?.Facebook_Link} className="social-icon social-icon--facebook"><i className="icon-facebook"></i></Link>
                                    : ''}
                                {data.glstrapi?.footer?.Instagram_Link ?
                                    <Link target="_blank" href={data.glstrapi?.footer?.Instagram_Link} className="social-icon social-icon--instagram"><i className="icon-instagram"></i></Link>
                                    : ''}
                                {data.glstrapi?.footer?.Twitter_Link ?
                                    <Link target="_blank" href={data.glstrapi?.footer?.Twitter_Link} className="social-icon social-icon--twitter"><i className="icon-twitter"></i></Link>
                                    : ''}
                                {data.glstrapi?.footer?.Youtube_Link ?
                                    <Link target="_blank" href={data.glstrapi?.footer?.Youtube_Link} className="social-icon social-icon--youtube"><i className="icon-youtube"></i></Link>
                                    : ''}
                                {data.glstrapi?.footer?.Linkedin_Link ?
                                    <Link target="_blank" href={data.glstrapi?.footer?.Linkedin_Link} className="social-icon social-icon--linkedin"><i className="icon-linkedin"></i></Link>
                                    : ''}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={5}>
                        <div className="copyright-block">
                            <p className="footer-link">
                                {data.glstrapi?.footer?.footer_links.length > 0 && data.glstrapi?.footer?.footer_links.map(item => (
                                    <GetURL label={item?.label} alias={item?.Menu_item?.URL} />
                                ))}
                                <a href="javascript:void(0);" id="open_preferences_center">Update cookies preferences</a>
                            </p>

                            <p>{year} Bentley Hurst Estate Agents. All Rights Reserved.<br /> Site by <a href={'https://starberry.tv/'} target="_blank"> {renderComponent && <img src={Starberry} alt="Starberry" className="stb-logo" />}</a></p>
                            <p>{'Bentley Hurst is the trading name of Bentley Hurst Limited registered in England & Wales (Reg No: 12797058)'}</p>
                        </div>
                    </Col>
                    <Col lg="7">
                        {data.glstrapi?.footer?.Show_Clients ?
                            <div className="logo-block">
                                {data.glstrapi?.footer?.Clients.map((node, i) => (
                                    <a href={node.Client_Url} target="_blank" >
                                        <img src={getSrc(node.Client_Logo?.url_sharp)} alt={node.Client_Logo?.alternativeText} />
                                    </a>
                                ))}
                            </div>
                            : ''}
                    </Col>
                </Row>
                {/* {renderComponent &&                       
                <CookieConsent
                    disableStyles={true}
                    // buttonClasses="btn btn-primary"
                    containerClasses="cookie-popup d-lg-flex d-block"
                    contentClasses=""
                    buttonClasses="btn btn-link gotit"
                    buttonText="Accept Cookies">

                    <p className="mb-0 text-center">We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to="/cookie-policy">Cookie Policy</Link>.</p>
                </CookieConsent>
                } */}

            </Container>
            <YomdelChatScript />
        </footer>
    )
}
export default Footer;
