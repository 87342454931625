export const URLS = {
  ABOUT: {
    name: 'About Bentley Hurst',
    alias: '/about-us'
  },
  rentService: {
    name: 'About Bentley Hurst',
    alias: '/property-services/rent/'
  },
  landlordService: {
    name: 'About Bentley Hurst',
    alias: '/property-services/landlord/'
  },
    TEAM: {
      name: 'Meet the team',
      alias: '/about-us/our-team'
    },
    AREA_GUIDES: {
      name: 'Manchester Area Guides',
      alias: '/about-us/areas-we-cover'
    },
    BLOGS: {
        name: 'Blog',
        alias: '/about-us/blog'
      },
      PROPERTY_NEWS: {
        name: 'Property News',
        alias: '/about-us/blog'
      },
      CAREERS: {
        name: 'Estate agent jobs in Manchester',
        alias: '/about-us/careers'
      },
      REVIEWS: {
        name: 'Reviews',
        alias: '/about-us/reviews'
      },
      VALUATION: {
        name: 'Property Valuation in Manchester',
        alias: '/property-valuation'
      },
      MARKET_YOUR_PROPERTY : {
        name: 'Market your Property',
        alias: '/market-your-property'
      },
      FORSALE: {
        name: 'For Sale',
        alias: '/property/for-sale'
      },
      TORENT: {
        name: 'To Rent',
        alias: '/property/to-rent'
      },
      FORSALED: {
        name: 'For Sale',
        alias: '/property-for-sale'
      },
      TORENTD: {
        name: 'To Rent',
        alias: '/property-to-rent'
      },
      SALE: {
        name: 'For Sale',
        alias: '/property-services/sell'
      },
      RENT: {
        name: 'To Rent',
        alias: '/property-services/rent'
      },
      PROPERTY_SERVICES_LET:{
        name: 'To Rent',
        alias: '/property-services/landlord'
      },
      BUY: {
        name: 'To Rent',
        alias: '/property-services/buy'
      },
      CONTACT: {
        name: 'Manchester Estate Agents',
        alias: '/contact-manchester-estate-agents'
      },
      CONTACTFORM: {
        name: 'Contact Us',
        alias: '/contact-manchester-estate-agents/contact'
      },
      FORSALE_Manchester: {
        name: 'Property for Sale in Manchester',
        alias: '/property/for-sale/in-manchester'
      },
      FORSALE_Deansgate: {
        name: 'Property for Sale in Deansgate',
        alias: '/property/for-sale/in-deansgate'
      },
      FORSALE_Salford: {
        name: 'Property for Sale in Salford',
        alias: '/property/for-sale/in-salford'
      },
      FORSALE_Hulme: {
        name: 'Property for Sale in Hulme',
        alias: '/property/for-sale/in-hulme'
      },

      TORENT_Manchester: {
        name: 'Property to Rent in Manchester',
        alias: '/property/to-rent/in-manchester/'
      },
      TORENT_Deansgate: {
        name: 'Property to Rent in Deansgate',
        alias: '/property/to-rent/in-deansgate'
      },
      TORENT_Salford: {
        name: 'Property to Rent in Salford',
        alias: '/property/to-rent/in-salford'
      },
      TORENT_Hulme: {
        name: 'Property to Rent in Hulme',
        alias: '/property/to-rent/in-hulme'
      },

      PROPERTY_SERVICES: {
        name: 'Property Services',
        alias: '/property-services'
      },

      PROPERTY_MANAGEMENT_MANCHESTER: {
        name: 'Property Management in Manchester',
        alias: '/property-services/landlord/property-management'
      },

      ZERO_DEPOSIT_SCHEME: {
        name: 'Zero deposit Scheme',
        alias: '/property-services/landlord/zero-deposit'
      }
      



}



export const TEAM_PAGE_URL = URLS.TEAM;
export const AREA_GUIDES_PAGE_URL = URLS.AREA_GUIDES;
export const BLOGS_PAGE_URL = URLS.BLOGS;
export const PROPERTY_NEWS_PAGE_URL = URLS.PROPERTY_NEWS;
export const CAREERS_PAGE_URL = URLS.CAREERS
export const VALUATION_PAGE_UEL = URLS.VALUATION
export const RENT_PAGE_URL = URLS.TORENT
export const SALE_PAGE_URL = URLS.FORSALE
export const DRENT_PAGE_URL = URLS.TORENTD
export const DSALE_PAGE_URL = URLS.FORSALED
export const REVIEWS_PAGE_URL = URLS.REVIEWS 
export const CONTACT_PAGE_URL = URLS.CONTACT
export const CONTACTFORM_PAGE_URL = URLS.CONTACTFORM
export const FORSALE_Manchester_PAGE_URL = URLS.FORSALE_Manchester
export const FORSALE_Deansgate_PAGE_URL = URLS.FORSALE_Deansgate
export const FORSALE_Salford_PAGE_URL = URLS.FORSALE_Salford
export const FORSALE_Hulme_PAGE_URL = URLS.FORSALE_Hulme

export const TORENT_Manchester_PAGE_URL = URLS.TORENT_Manchester
export const TORENT_Deansgate_PAGE_URL = URLS.TORENT_Deansgate
export const TORENT_Salford_PAGE_URL = URLS.TORENT_Salford
export const TORENT_Hulme_PAGE_URL = URLS.TORENT_Hulme

export const ABOUT_PAGE_URL = URLS.ABOUT
export const PROPERTY_SERVICES_PAGE_URL = URLS.PROPERTY_SERVICES
export const MARKET_YOUR_PROPERTY_PAGE_URL = URLS.MARKET_YOUR_PROPERTY
export const PROPERTY_MANAGEMENT_MANCHESTER_PAGE_URL = URLS.PROPERTY_MANAGEMENT_MANCHESTER
export const ZERO_DEPOSIT_SCHEME_PAGE_URL = URLS.ZERO_DEPOSIT_SCHEME