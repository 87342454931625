import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Highlight, connectAutoComplete } from 'react-instantsearch-dom';
import AutoSuggest from 'react-autosuggest';

class AutoComplete extends Component {
    static propTypes = {
      hits: PropTypes.arrayOf(PropTypes.object).isRequired,
      currentRefinement: PropTypes.string.isRequired,
      refine: PropTypes.func.isRequired,
      onSuggestionSelected: PropTypes.func.isRequired,
      onSuggestionCleared: PropTypes.func.isRequired,
    };
  
    state = {
      value: this.props.currentRefinement,
    };
  
    onChange = (_, { newValue }) => {
      if (!newValue) {
        this.props.onSuggestionCleared();
      }
  
      this.setState({
        value: newValue,
      });
    };
  
    onSuggestionsFetchRequested = ({ value }) => {
      this.props.refine(value);
    };
  
    onSuggestionsClearRequested = () => {
      this.props.refine();
    };
  
    getSuggestionValue(hit) {
      return hit.area;
    }
  
    renderSuggestion(hit) {
      return <Highlight attribute="area" hit={hit} tagName="mark" />;
    }
  
    render() {
      const { hits, onSuggestionSelected } = this.props;
      const { value } = this.state;
  
      const inputProps = {
        placeholder: 'Postcode, Town or City',
        onChange: this.onChange,
        value,      
      };

      // Remove duplicates
      function getUniqueListBy(hits, key) {
        return [...new Map(hits.map(item => [item[key], item])).values()]
      }
      const hitsNew = getUniqueListBy(hits, 'area');
  
      return (
        <AutoSuggest
          suggestions={hitsNew}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
      );
    }
  }
  
  export default connectAutoComplete(AutoComplete);